import React from 'react';
import { Form, Input, Button, Typography, message } from 'antd';
import Request from './../../core/fetch';
import './style.scss';


export class AuthCtrl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuth: false,
            isLoading: false,
        };
    }

    componentDidMount() {}

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ isLoading: true }, () => {
                    Request(`/authorization`, `POST`, values).then(res => {
                        const { access_token, refresh_token } = res.data;
                        this.props.setAuth(access_token, refresh_token)
                        this.setState({ isLoading: false });
                    }).catch(err => {
                        console.error(`[Authorization]: `, err);
                        if (err.status === 401) {
                            message.error(`Не верный логин или пароль`);
                        } else {
                            message.error(`Ошибка авторизации`);
                        }
                        this.setState({ isLoading: false });
                    });
                });
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div className="controller auth-ctrl">
                <img src="/images/logos/logo.svg" alt="MD24" className="form--image"/>
                <div className="form--wrapper">
                    <Typography.Title level={2}>Авторизация</Typography.Title>
                    <Form onSubmit={this.onSubmit}>
                        <Form.Item label={`Email`}>
                            {getFieldDecorator('email', {
                                rules: [
                                    {required: true, message: 'Введите email'},
                                    {type: 'email', message: 'Введите корректный email'},
                                ]
                            })(<Input type="email" placeholder="Введите ваш email"/>)}
                        </Form.Item>
                        <Form.Item label={`Пароль`}>
                            {getFieldDecorator('password', {
                                rules: [
                                    {required: true, message: 'Введите пароль'},
                                ]
                            })(<Input type="password" placeholder="Введите ваш пароль"/>)}
                        </Form.Item>
                        <Form.Item><Button type='primary' htmlType='submit' loading={this.state.isLoading}>Войти</Button></Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}

export const AuthController = Form.create({ name: 'auth_controller' })(AuthCtrl);
export default AuthController;