import React from 'react';
import {Empty, Input, Form, Button, AutoComplete, Spin, Checkbox, Select, Modal} from "antd";
import {t} from "../../../core/library/i18n";
import Config from './../../../config';
import Cropper from "../../../components/cropper";
import { MediaModel } from './cover';



export class FileForm extends React.Component {

    static defaultProps = {
        onSubmit: () => {},
    }

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            cropModal: false,
            uploadImage: null,
            coverModal: false,
            coverPath: '',
        }
    }

    isVideo = () => {
        const { isUploading } = this.props;
        if (isUploading === true) {
            let params = this.props.originFileObj.type.split('/');
            return params[0] === "video";
        }
        if (this.props.item && this.props.item.type === 'Video') {
            return true;
        }
        return false;
    }

    generateImagePreview = () => {
        if (!this.props.isEdit) {
            let params = this.props.originFileObj.type.split('/');
            switch (params[0]) {
                case "image":
                    return <img alt={`data`} src={window.URL.createObjectURL(this.props.originFileObj)}/>;
                case "video":
                    
                    return <video controls src={window.URL.createObjectURL(this.props.originFileObj)}/>;
                default:
                    return <div className={`mg__create--empty`}><Empty
                        description={t(`MEDIGALLERY_CANNOT_GENERATE_PREVIEW`)}/></div>;
            }
        } else {
            const files = this.props.item && this.props.item.files ? this.props.item.files : {};
            if (Object.keys(files).length === 0) {
                return <div className={`mg__create--empty`}><Empty
                    description={t(`MEDIGALLERY_CANNOT_GENERATE_PREVIEW`)}/></div>;
            } else {
                switch(this.props.item.type) {
                    case "Video":
                        let poster = this.props.item && this.props.item.poster ? this.props.item.poster : "";
                        if (poster !== "") {
                            poster = `${Config.MEDIA.CDN.IMAGES}/${poster}`
                        }
                        return <video style={{ display: 'inline-block', margin: '0 auto', width: '100%', height: 'auto', maxWidth: '500px' }} controls poster={poster} src={`${Config.MEDIA.CDN.VIDEO}/${files['original'].path}`}/>;
                    case "Image":
                    case "Cover":
                        let paths = files['original'].path;
                        if (files['image-l']) {
                            paths = files['image-l'].path;
                        }

                        return <img style={{  display: 'inline-block', margin: '0 auto', width: '100%', height: 'auto', maxWidth: '500px' }} alt={`data`} src={`${Config.MEDIA.CDN.IMAGES}/${paths}`}/>;
                    default:
                        return <div className={`mg__create--empty`}><Empty
                            description={t(`MEDIGALLERY_CANNOT_GENERATE_PREVIEW`)}/></div>;
                }
            }
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values);
            }
        });
    }

    getUnique = (arr = []) => {
        let data = [];
        arr.forEach(i => {
            if (data.indexOf(i) + 1 === 0) {
                data.push(i);
            }
        });
        return data;
    }

    openModal = () => {
        let reader = new FileReader();
        reader.onload = (f) => {
            this.setState({uploadImage: reader.result, cropModal: true});
        }
        reader.readAsDataURL(this.props.originFileObj);
    }

    closeModal = () => {
        this.setState({cropModal: false, uploadImage: null});
    }



    onCropFinish = (image, base64) => {
        this.setState({
            image: base64,
            uploadImage: image,
            cropModal: false,
        }, () => {
            this.props.onUpdateFile(this.props.uid, this.state.uploadImage);
        })
    }


    openCoverModal = () => {
        this.setState({ coverModal: true });
    }

    closeCoverModal = () => {
        this.setState({ coverModal: false });
    }

    onSetPoster = (poster) => {
        this.closeCoverModal();
        const item = this.props.item ? this.props.item : this.props.props;
        this.props.onSubmit({...item, ...{poster}})
    }

    onDeletePoster = () => {
        const item = this.props.item ? this.props.item : this.props.props;
        this.props.onSubmit({...item, ...{poster: ""}})
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const item = this.props.item ? this.props.item : this.props.props;
        const { cropModal, uploadImage } = this.state;
        let params = this.props.originFileObj && this.props.originFileObj.type ?  this.props.originFileObj.type.split('/') : "";

        return (
            <div className={`mg--upload--item`}>
                {this.props.originFileObj && (
                    <Cropper
                        visible={cropModal}
                        image={uploadImage}
                        onClose={this.closeModal}
                        onFinish={this.onCropFinish}
                        name={this.props.originFileObj.name}
                        type={this.props.originFileObj.type}
                    />
                )}

                <Modal title={`Установка обложки для видео`} onCancel={this.closeCoverModal} width={'90%'} destroyOnClose={true} visible={this.state.coverModal} footer={null}>
                    {this.state.coverModal && <MediaModel type={'Image,Cover'} onSetPoster={this.onSetPoster}/>}
                </Modal>

                
                <Spin style={{display: 'flex', flexDirection: 'row'}} spinning={this.props.loading}
                      tip={`Идет загрузка`}>
                    <div className="preview" style={{textAlign: 'center'}}>
                        {this.generateImagePreview()}
                        {!this.props.isEdit && (
                            <div className="actions">
                                <Button onClick={(e) => {
                                    e.preventDefault();
                                    this.props.removeImageHandle(this.props.uid);
                                }} icon={`delete`} type={`danger`}>Удалить</Button>
                                {params[0] === "image" && (<Button style={{marginLeft: 12}} onClick={(e) => {e.preventDefault(); this.openModal()}} icon={`edit`} type={`primary`}>Кадрировать</Button>)}
                            </div>
                        )}
                        {item.user_email && <div className={`user_email`}>Загрузил: {item.user_email}</div> }
                        {item.type === 'Video' && <div className="btn_wrp">
                            <Button.Group>
                                <Button type='primary' onClick={this.openCoverModal}>Установить обложку</Button>
                                {item.poster && <Button type='danger' onClick={this.onDeletePoster}>Удалить обложку</Button>}
                            </Button.Group>
                            
                            
                        </div>}

                    </div>
                    <div className="form">
                        <Form onSubmit={this.onSubmit}>
                            <Form.Item label={`Заголовок`}>
                                {getFieldDecorator('title', {
                                    initialValue: item.title || "",
                                })(<Input/>)}
                                {this.props.showCopy && <Button icon={`copy`} onClick={(e) => {
                                    e.preventDefault();
                                    this.props.copyFieldToAll(this.props.uid, 'title');
                                }}/>}
                            </Form.Item>
                            <Form.Item label={`Описание`}>
                                {getFieldDecorator('description', {
                                    initialValue: item.description || "",
                                })(<Input.TextArea rows={5}/>)}
                                {this.props.showCopy && <Button icon={`copy`} onClick={(e) => {
                                    e.preventDefault();
                                    this.props.copyFieldToAll(this.props.uid, 'description');
                                }}/>}
                            </Form.Item>
                            <Form.Item label={`Ключевые слова`}>
                                {getFieldDecorator('keywords', {
                                    initialValue: item.keywords || "",
                                })(<Input/>)}
                                {this.props.showCopy && <Button icon={`copy`} onClick={(e) => {
                                    e.preventDefault();
                                    this.props.copyFieldToAll(this.props.uid, 'keywords');
                                }}/>}
                            </Form.Item>
                                <Form.Item label={`Скрыть через 24 часа`}>
                                    {getFieldDecorator('expired', {
                                        initialValue: item.expired || false,
                                        valuePropName: 'checked',
                                    })(<Checkbox />)}
                                </Form.Item>
                            <Form.Item label={`Копирайт`}>
                                {getFieldDecorator('properties.copyright', {
                                    initialValue: item.properties && item.properties.copyright ? item.properties.copyright : "",
                                })(<AutoComplete
                                    backfill={true}
                                    dataSource={this.getUnique(Config.PHOTO_AUTHORS)}
                                    filterOption={true}><Input/></AutoComplete>)}
                                {this.props.showCopy && <Button icon={`copy`} onClick={(e) => {
                                    e.preventDefault();
                                    this.props.copyFieldToAll(this.props.uid, 'properties.copyright');
                                }}/>}
                            </Form.Item>
                            {(this.isVideo() || item.type === "Video") && (
                                <Form.Item label={t('SOURCE')} >
                                    {getFieldDecorator('properties.source', {
                                        initialValue: item && item.properties ? item.properties.source : '',
                                        rules: [],
                                    })(
                                        <Select placeholder="Select media source">
                                            {(Config.MEDIA.SOURCES && Config.MEDIA.SOURCES.length > 0) && Config.MEDIA.SOURCES.map(i => {
                                                return <Select.Option value={i} key={`media_source_${i}`}>{i}</Select.Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            )}

                            <Form.Item label={`Ссылка на истоник`}>
                                {getFieldDecorator('properties.copyrightUrl', {
                                    initialValue: item.properties && item.properties.copyrightUrl ? item.properties.copyrightUrl : "",
                                })(<Input/>)}
                                {this.props.showCopy && <Button icon={`copy`} onClick={(e) => {
                                    e.preventDefault();
                                    this.props.copyFieldToAll(this.props.uid, 'properties.copyrightUrl');
                                }}/>}
                            </Form.Item>

                            {this.isVideo() && (
                                <Form.Item label={`Вертикальное видео`}>
                                    {getFieldDecorator('vertical', {
                                        valuePropName: 'checked',
                                        initialValue: item.vertical || false,
                                    })(<Checkbox />)}
                                </Form.Item>
                            )}
                            {this.props.isEdit && (
                                <Form.Item>
                                    <Button type={`primary`} htmlType={`submit`}>Сохранить</Button>
                                </Form.Item>
                            )}
                        </Form>
                    </div>
                </Spin>
            </div>

        );
    }
}

export const EditForm = Form.create({name: 'upload_form'})(FileForm);
