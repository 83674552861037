import React from 'react';
import { Button, Tag } from 'antd';
import Config, { GetImagePath } from './../../../../../../../config';
import { t } from '../../../../../../library/i18n';




export default class List extends React.Component {

    static defaultProps = {
        onInsert: () => {},
        type: window.location.pathname.indexOf('/regionmat/') + 1 > 0 ? "RegionImage" : "Image",
    };

    prepareText = (item) => {
        const params = [item.title];
        if (item.description) {
            params.push(item.description);
        }
        if (item.properties) {
            Object.keys(item.properties).map(i => {
                if (item.properties[i] !== undefined) {
                    params.push(item.properties[i])
                }
                return i;
            });
        }
        return params.join('/');
    };

    getImagePath = (path) => {
        return GetImagePath({'original': path}, '320');
    }

    render() {
        const mediaList = this.props.media.filter(i => {
            return i.type === this.props.type;
        });
        return (
            <div className={`media--selected_list`}>
                {mediaList.map(i => {
                    let path = i.files.original.path;
                    if (i.files && i.files['image-l'] && i.files['image-l'].path) {
                        path = i.files['image-l'].path;
                    }
                    return (
                        <div className={`media--selected_item type_${i.type.toLowerCase()}`} key={`selected_${i.id}`}>
                            {i.type === 'Image' && (<div className="cover" style={{backgroundImage: `url(${Config.MEDIA.CDN.IMAGES}/${path})`}} />)}
                            {i.type === 'RegionImage' && (<div className="cover" style={{backgroundImage: `url(${Config.MEDIA.CDN.IMAGES}/${path})`}} />)}
                            {i.type === 'Video' && (
                                <div className="cover">
                                    {i.main && <Tag color={'green'} className={`label-main`}>Главное</Tag>}
                                    <video controls src={`${Config.MEDIA.CDN.VIDEO}/${i.files.original.path}`}></video>
                                </div>
                            )}
                            {i.type === 'RegionVideo' && (
                                <div className="cover">
                                    <video controls src={`${Config.MEDIA.CDN.VIDEO}/${i.files.original.path}`}></video>
                                </div>
                            )}

                            {i.type === 'ExternalVideo' && (
                                <div className="cover" dangerouslySetInnerHTML={{__html: i.embed}}/>
                            )}
                            <div className="info">
                                {this.prepareText(i)}
                                {i.type === "ExternalVideo" && i.files && i.files.original ? i.files.original.path : ""}
                                <div>
                                    <Button
                                        type={`primary`}
                                        onClick={() => this.props.onInsert(i)}
                                        icon={`plus`}>
                                            {t('INSERT')}
                                    </Button>
                                </div>
                            </div>
                            <div className="actions">
                                <Button type={`primary`} icon={`edit`} onClick={() => {
                                    this.props.openModalEdit(i);
                                }}/>
                                <Button
                                    type={`danger`}
                                    icon={`delete`}
                                    onClick={() => {
                                        this.props.onDeleteMedia(i.id);
                                    }}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }
}
