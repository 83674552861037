import React from 'react';
import FormGenerator from './../../../core/components/generators/form';
import {t} from '../../../core/library/i18n';
import PrepareInitialValue from "../../../core/library/form/prepare";

export class AddPodcast extends React.Component {
    constructor(props) {
        super(props);
        this.state = {reload: false};
        this.Fields = [
            {
                'component': 'input',
                'label': t('TITLE'),
                'name': "title",
                "show_count": true,
                'props': {
                    'type': 'text',
                    'placeholder': t('TITLE_PLACEHOLDER'),
                    'onBlur': (e) => {
                        if (e.target.value !== this.props.item.title) {
                            // this.props.onUpdate({title: e.target.value}, true);
                        }
                    }
                },
                'rules': [
                    {required: true, message: t('TITLE_ERROR_FILL')},
                ]
            },
            {
                'component': 'media',
                'type': 'Cover',
                'searchType': 'Cover,Image',
                'label': t('COVER_LABEL'),
                'name': 'media',
            },
            {
                'component': 'media',
                'type': 'CoverSquare',
                'searchType': 'CovertSquare,Image',
                'label': t('CoverSquare'),
                'name': 'media',
                'props': {
                    'overwrite': true,
                    'overwrite_to': 'media_cover_square',
                },
            },
            {
                'component': 'upload',
                'type': 'Audio',
                'label': t('AUDIO'),
                'name': 'media',
                'overwrite': 'audio',
                'props': {
                    'accept': '.mp3',
                }
            },
            // {
            //     'component': 'upload',
            //     'type': 'Podcast',
            //     'label': t('PODCAST_AUDIO'),
            //     'name': 'media',
            //     'accept': '.mp3'

            // },

            {
                'component': 'editor',
                'label': t('TEXT123'),
                'name': 'text',
                'props': {
                    rows: 8,
                },
            },

            {
                'component': 'editor',
                'label': t('TRANSCRIPT'),
                'name': 'transcript',
                'props': {
                    rows: 8,
                },
            },
            {
                'component': 'slug',
                'label': t('SLUG'),
                'name': 'slug',
                'source_field': 'title',
                'rules': [
                    {required: true, message: t("SLUG_ERROR")},
                ]
            },
            {
                'component': 'datepicker',
                'label': t('PUBLISHED_AT'),
                'name': 'publishedAt',
                'props': {
                    placeholder: 'Укажите дату и время публикации',
                    showTime: true,
                }
            },
            {
                'component': 'group',
		        'label': t('DATABASE_LIST'),
                'fields': [
                    {
                        'component': 'autocomplete',
                        'type': 'Author',
                        'limit': 30,
                        'label': t('AUTHORS'),
                        'name': 'authors',
                        'props': {
                            placeholder: t('AUTHORS_PLACEHOLDER'),
                            mode: 'multiple',
                            allowClear: true,
                            showSearch: true,
                        },
                        'rules': [
                            {required: true, message: t('SELECT_AUTHORS_ERROR')},
                        ]
                    },
                    {
                        'component': 'input_number',
                        'label': 'Номер выпуска',
                        'name': 'options.output_number',
                    },

                    {
                        'component': 'input_number',
                        'label': t('TIME_TO_LISTEN'),
                        'name': 'options.time_to_listen',
                    },
                    {
                        'component': 'autocomplete',
                        'type': 'Seasons',
                        'limit': 30,
                        'label': t('SEASON'),
                        'name': 'category',
                        'props': {
                            placeholder: `Выберите сезон`,
                            allowClear: true,
                            showSearch: true,
                        },
                        'rules': [
                            {required: true, message: t('Укажите сезон')},
                        ]
                    },
                    
                    {
                        'component': 'autocomplete',
                        'type': 'Tag',
                        'limit': 15,
                        'label': t('TAGS'),
                        'name': 'tags',
                        'props': {
                            placeholder: t('SELECT_TAGS'),
                            allowClear: true,
                            showSearch: true,
                            mode: 'multiple',
                        },
                    },
                    {
                        'component': 'autocomplete',
                        'type': 'Trend',
                        'limit': 15,
                        'label': t('TRENDS'),
                        'name': 'trends',
                        'props': {
                            placeholder: t('SELECT_TRENDS'),
                            allowClear: true,
                            showSearch: true,
                            mode: 'multiple',
                        },
                    },
                    {
                        'component': 'autocomplete',
                        'type': 'Labels',
                        'limit': 15,
                        'label': t('LABELS'),
                        'name': 'labels',
                        'props': {
                            placeholder: t('SELECT_LABELS'),
                            allowClear: true,
                            showSearch: true,
                            mode: 'multiple',
                        },
                    }
                ]
            }
            // {
            //     'component': 'group',
		    //     'label': t('DATABASE_LIST'),
            //     'fields': [
            //         {
            //             'component': 'autocomplete',
            //             'type': 'Author',
            //             'limit': 30,
            //             'label': t('AUTHORS'),
            //             'name': 'authors',
            //             'props': {
            //                 placeholder: t('AUTHORS_PLACEHOLDER'),
            //                 mode: 'multiple',
            //                 allowClear: true,
            //                 showSearch: true,
            //             },
            //             'rules': [
            //                 {required: true, message: t('SELECT_AUTHORS_ERROR')},
            //             ]
            //         },
            //         {
            //             'component': 'autocomplete',
            //             'type': 'Category',
            //             'limit': 15,
            //             'label': t('CATEGORY'),
            //             'name': 'category',
            //             'props': {
            //                 placeholder: t('SELECT_CATEGORIES'),
            //                 allowClear: true,
            //                 showSearch: true,
                
            //             },
            //             'rules': [
            //                 {required: true, message: t('SELECT_CATEGORIES')},
            //             ]
            //         },
            //         {
            //             'component': 'autocomplete',
            //             'type': 'Tag',
            //             'limit': 15,
            //             'label': t('TAGS'),
            //             'name': 'tags',
            //             'props': {
            //                 placeholder: t('SELECT_TAGS'),
            //                 allowClear: true,
            //                 showSearch: true,
            //                 mode: 'multiple',
            //             },
            //         },
            //         {
            //             'component': 'autocomplete',
            //             'type': 'Trend',
            //             'limit': 15,
            //             'label': t('TRENDS'),
            //             'name': 'trends',
            //             'props': {
            //                 placeholder: t('SELECT_TRENDS'),
            //                 allowClear: true,
            //                 showSearch: true,
            //                 mode: 'multiple',
            //             },
            //         },
            //         {
            //             'component': 'checkbox',
            //             'label': t('IMPORTANT_NEWS'),
            //             'name': 'options.important_news',
            //         },
            //         {
            //             'component': 'checkbox',
            //             'label': t('BREAKING_NEWS'),
            //             'name': 'options.breaking_news',
            //         },
            //         {
            //             'component': 'checkbox',
            //             'label': t('EXCLUSIVE_NEWS'),
            //             'name': 'options.exclusive',
            //         },
            //     ]
            // }
        ];

        if (['podcasts'].indexOf(this.props.type) + 1 > 0) {
            this.Fields = [...this.Fields, ...[{
                'component': 'group',
                'label': t('CROSS_LINKS'),
                'fields': [
                    {
                        'name': 'crosslinks',
                        'component': 'crosslinks',
                        'label': t('CROSS_LINKS'),
                        'props': {
                            placeholder: t('SELECT_SUB_CATEGORIES'),
                            allowClear: true,
                            showSearch: true,
                            mode: 'multiple',
                        }
                    },
                ]
            }]];
        }
        
        this.Fields = [...this.Fields, ...[
            {
                'component': 'group',
                'label': t('SEO_AND_VIEW'),
                'fields': [
                    {
                        'component': 'view',
                        'label': t('VIEW_FIELD'),
                        'name': 'view',
                        'type': 'doc',
                    },
                    {
                        'component': 'input',
                        'label': t('SEO_TITLE'),
                        'name': "seo.metaTitle",
                        'props': {
                            'type': 'text',
                            'placeholder': 'seo title',
                        },
                    },
                    {
                        'component': 'textarea',
                        'label': t('SEO_DESCRIPTION'),
                        'name': "seo.metaDescription",
                        'props': {
                            'type': 'text',
                            'placeholder': 'seo description',
                        },
                    },
                    {
                        'component': 'input',
                        'label': t('REDIRECT_TO'),
                        'name': "redirectTo",
                        'props': {
                            'type': 'text',
                            'placeholder': 'redirect url',
                        },
                    }
                ],
            }
        ]];
    }

    componentWillReceiveProps(p) {
        if (p.item && p.item.id) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
        }
        if (p.showLock !== this.props.showLock) {
            this.Fields = PrepareInitialValue(p.item, this.Fields, p.showLock);
            this.setState({reload: !this.state.reload}, () => {
            });
        }
    }

    onSubmit = (values) => {
    
        let result_values = {};
        Object.keys(values).map(i => {
            if (i !== "text" && values[i] !== "") {
                result_values[i] = values[i];
            }
            if (i === "text") {
                result_values[i] = values[i];
            }

            if (i === "summary") {
                result_values[i] = values[i];
            }
            return i
        });

        values = result_values;

        let media = [];
        if (values.media) {
            media = Object.keys(values.media).map(i => values.media[i]);
        }
        values.redirectTo = values.redirectTo || "";
        values.media = media;
        if (this.props.item && this.props.item.id) {
            this.props.onUpdate(values);
        } else {
            this.props.onCreate(values);
        }
    };

    render() {
        return (
            <div className={`app_add--view add_${this.props.type}`}>
                <FormGenerator
                    showLock={this.props.showLock}
                    fields={this.Fields}
                    onSubmit={this.onSubmit}
                    onUpdate={this.props.onUpdate}
                    onPreview={this.props.onPreview}
                    isEdit={this.props.isEdit}
                    loading={this.props.loading}
                    item={this.props.item}
                    showPreview={true}
                    updateStash={this.props.updateStash}
                    updateMedia={this.props.updateMedia}
                    updateItem={this.props.updateItem}
                    updateMaterialType={this.props.updateMaterialType}
                    sendPush={this.props.sendPush}
                    hideOptions={false}
                />
            </div>
        );
    }
}

export default AddPodcast;
