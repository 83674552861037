import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Lightbox from 'react-lightbox-component';
import { Link } from 'react-router-dom';
import 'react-lightbox-component/build/css/index.css';
import { Divider, message, Tooltip, Icon, Spin, Radio, Checkbox, Button, Pagination } from 'antd';
import Moment from 'moment';
import 'moment/locale/ru';
import {GetCover} from './../../core/components/cover';

import * as Actions from "./../../store/documents/actions";
import * as C from './../../store/documents/constants';
import { t } from './../../core/library/i18n';


const WorkflowPagination = (p) => {
    return (
        <Pagination
            onChange={p.updatePageHandle}
            total={p.total}
            pageSize={p.limit}
            defaultCurrent={p.page}
            current={p.page}
            hideOnSinglePage={true}
            showTotal={(total) => `${total} ${t('DOCUMENTS')}`}
        />
    );
};


const mapStateToProps = state => ({
    answer: state.DocumentsReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class DashboardItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            items: [],
            total: 0,
            limit: 30,
            page: 1,
            selectedWorkflow: '',
            filterOptions: [],
        };
    }

    componentDidMount() {
        this.loadWorkflow();
        window.addEventListener('keyup', this.keydownEvent)
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        window.addEventListener('keyup', this.keydownEvent)
    }

    incPage = () => {
        let page = this.state.page + 1;
        if (page > this.state.total) {
            page = this.state.total;
        }
        this.updatePageHandle(page);
    };

    decPage = () => {
        let page = this.state.page - 1;
        if (page < 1) {
            page = 1;
        }
        this.updatePageHandle(page);
    };

    keydownEvent = (e) => {
        switch(e.keyCode) {
            case 37:
                this.decPage();
                break;
            case 39:
                this.incPage();
                break;
            default: break;
        }
    };

    updatePageHandle = (page) => {
        this.setState({ page }, () => {
            this.loadWorkflow();
        });
    };

    loadWorkflow = () => {
        if (this.state.selectedWorkflow) {
            this.props.actions.GetWorkflow([this.state.selectedWorkflow], this.state.page, '', this.state.filterOptions);
        } else {
            if (window.user.is_admin || window.user.email === 'v.kuzminova@vm.ru') {
                this.props.actions.GetWorkflow(null,  this.state.page, '', this.state.filterOptions);
            } else {
                if (window.user.group && window.user.group.workflow && window.user.group.workflow.length > 0) {
                    this.props.actions.GetWorkflow(window.user.group.workflow,  this.state.page, '', this.state.filterOptions);
                }
            }
        }
    };

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.DOCUMENTS_WORKFLOW_START:
                    this.setState({ loading: true });
                    break;
                case C.DOCUMENTS_WORKFLOW_FINISH:
                    this.setState({
                        loading: false,
                        items: p.answer.data.list || [],
                        limit: p.answer.data.limit ? parseInt(p.answer.data.limit, 10) : 0,
                        total: p.answer.data.total ? parseInt(p.answer.data.total, 10) : 0,
                    }, () => {
                        this.timeout = setTimeout(() => {
                            this.loadWorkflow();
                        }, 120 * 1000);
                    });
                    break;
                case C.DOCUMENTS_WORKFLOW_ERROR:
                    message.error(t('ERROR_LOAD_WORKFLOW'));
                    this.setState({ loading: false });
                    break;
                default: break;
            }
        }
    }

    prepareAuthors = (authors) => {
        let result = [];
        if (authors === null || !Array.isArray(authors)) return null;
        result = authors.map(i => {
            return <a key={`author_item_${i.id}`} href={`/documents/author/${i.id}`} target={`_blank`}>{i.title}</a>;
        });
        return result;
    };


    updateOptions = (v) => {
        this.setState({filterOptions: v, page: 1}, () => {
            this.loadWorkflow();
        });
    };


    coordExists = (i) => {
        if(i.options && i.options.coords && i.options.coords !== "") {
            return true;
        }

        if (i.geo && Array.isArray(i.geo) && i.geo.length > 0) {
            return true;
        }

        return false;
    };



    render() {
        const optionsFilter = [
            {value: 'needPhoto', label: t('IMAGE_FIELD')},
            {value: 'needVideo', label: t('VIDEO_FIELD')},
            {value: 'needRayter', label: t('RAITER_FIELD')},
            {value: 'needEditor', label: t('EDITOR_FIELD')},
            {value: 'needCorector', label: t('CHECK_FIELD')},
            {value: 'main', label: t('MAINPAGE_FIELD')},
        ];
        return (
            <div className={`dashboard-workflow`}>
                <Divider>{t('WORKFLOW')}: {Moment(new Date()).format('HH:mm:ss')}</Divider>
                <div className="dashboard--table">
                    <Spin spinning={this.state.loading}>
                        <div className="table--filter">
                            <div className="filter--status">
                                <Radio.Group size={`small`} buttonStyle="solid" onChange={(e) => {this.setState({page: 1,selectedWorkflow: e.target.value}, () => { this.loadWorkflow() })}} value={this.state.selectedWorkflow}>
                                    <Radio.Button value={`DRAFT`}><Icon style={{fontSize: 15}} type={'snippets'} /><span>{t('DRAFT')}</span></Radio.Button>
                                    <Radio.Button value={`PUBLISH`}><Icon style={{fontSize: 15}} type={'check'} /><span>{t('PUBLISH')}</span></Radio.Button>
                                    <Radio.Button value={`READY`}><Icon style={{fontSize: 15}} type={'container'} /><span>{t('READY')}</span></Radio.Button>
                                    <Radio.Button value={`ARCHIVE`}><Icon style={{fontSize: 15}} type={'database'} /><span>{t('ARCHIVE')}</span></Radio.Button>
                                    <Radio.Button value={`PREPUBLISHED`}><Icon style={{fontSize: 15}} type={'clock-circle'} /><span>{t('PREPUBLISHED_DASHBOARD')}</span></Radio.Button>
                                </Radio.Group>
                            </div>
                            {/*<div className="filter--checkbox">*/}
                            {/*    <Checkbox.Group size={`small`} options={optionsFilter} value={this.state.filterOptions} onChange={this.updateOptions}/>*/}
                            {/*</div>*/}
                            <div className="filter--clear">
                                <Button type={`primary`} onClick={() => {
                                    this.setState({selectedWorkflow: "", filterOptions: [], page: 1}, () => {
                                        this.loadWorkflow();
                                    });
                                }}>{t('CLEAR')}</Button>
                            </div>
                        </div>
                        <div className="table--pagination">
                            <WorkflowPagination updatePageHandle={this.updatePageHandle} total={this.state.total} limit={this.state.limit} page={this.state.page}/>
                        </div>
                        <table>
                            <tbody>
                                {this.state.items.map( i => {
                                    let c = GetCover(i);
                                    if (i.type === "Meaning" && i.options && i.options["author_cover"]) {
                                        c = i.options["author_cover"];
                                    }

                                    return (
                                        <tr key={`item_${i.id}`}>
                                            <td className={`wf-status`}>
                                                {i.workflow && i.workflow === 'DRAFT' &&  <Tooltip title={t(i.workflow)}><Icon style={{fontSize: 15}} type={'snippets'} /></Tooltip>}
                                                {i.workflow && i.workflow === 'PUBLISH' && <Tooltip title={t(i.workflow)}><Icon style={{fontSize: 15}} type={'check'} /></Tooltip>}
                                                {i.workflow && i.workflow === 'READY' && <Tooltip title={t(i.workflow)}><Icon style={{fontSize: 15}} type={'container'} /></Tooltip>}
                                                {i.workflow && i.workflow === 'ARCHIVE' && <Tooltip title={t(i.workflow)}><Icon style={{fontSize: 15}} type={'database'} /></Tooltip>}
                                            </td>
                                            <td className={`created`}>{Moment(i.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                                            <td className={`title`}>
                                                {i.locks && i.locks.id && <div className={`lock`}><Tooltip title={`${t('LOCK_BY')} ${i.locks.user_id.email || ""}`}><Icon type="lock" theme="filled" /></Tooltip></div>}
                                                <Tooltip title={i.title}><Link target={"_blank"} to={`/documents/${i.type.toLowerCase()}/${i.id}`}>{i.title.length > 80 ? i.title.substr(0, 77) + "..." : i.title}</Link></Tooltip>
                                            </td>
                                            <td className={`authors`}>{this.prepareAuthors(i.authors || [])}</td>
                                            <td className={`status ${i.options && i.options.main ? ``: `bad`}`}><Tooltip title={t('MAINPAGE_PUBLISH')}><Icon style={{fontSize: 15}} type={`appstore`} theme={`filled`} /></Tooltip></td>
                                            <td className={`status ${i.options && i.options.needPhoto ? ``: `bad`}`}><Tooltip title={t('IMAGE_FIELD')}><Icon style={{fontSize: 15}} type={`picture`} theme={`filled`} /></Tooltip></td>
                                            <td className={`status ${i.options && i.options.needVideo ? ``: `bad`}`}><Tooltip title={t('VIDEO_FIELD')}><Icon style={{fontSize: 15}} type={`youtube`} theme={`filled`}/></Tooltip></td>
                                            <td className={`status ${i.options && i.options.needRayter ? ``: `bad`}`}><Tooltip title={t('RAITER_FIELD')}><Icon style={{fontSize: 15}} type={`pushpin`} theme={`filled`}/></Tooltip></td>
                                            <td className={`status ${i.options && i.options.needEditor ? ``: `bad`}`}><Tooltip title={t('EDITOR_FIELD')}><Icon style={{fontSize: 15}} type={`edit`} theme={`filled`}/></Tooltip></td>
                                            <td className={`status ${i.options && i.options.needCorector ? ``: `bad`}`}><Tooltip title={t('CHECK_FIELD')}><Icon style={{fontSize: 15}} type={`highlight`} theme={`filled`}/></Tooltip></td>
                                            <td className={`status ${i.antp && i.antp.original_percent ? ``: `bad`}`}><Tooltip title={t('AN_WORKFLOW_ORIGINAL')}>{i.antp && i.antp.original_percent ? `${i.antp.original_percent.toFixed(2)}%` : '0'}</Tooltip></td>

                                            <td className={`user-created`}>{Moment(i.updatedAt).format('YYYY-MM-DD HH:mm')} / <a href={`/users/${i.updatedBy.id}`} target={`_blank`}>{i.updatedBy.email}</a></td>
                                            <td className={`actions`}>0 / {i.views_count}</td>
                                            <td className={`cover`}>
                                                {c && <Lightbox images={[{src: c}]} />}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className="table--pagination">
                            <WorkflowPagination updatePageHandle={this.updatePageHandle} total={this.state.total} limit={this.state.limit} page={this.state.page}/>
                        </div>
                    </Spin>
                </div>
            </div>
        );
    }
}


export const DefaultDocument = connect(
    mapStateToProps,
    mapDispatchProps
)(DashboardItems);

export default DefaultDocument;
