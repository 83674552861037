import React from 'react';
import PropTypes from 'prop-types';
import {Button, Tag, Tooltip, Popconfirm, Icon, Checkbox} from 'antd';

import {t} from "../../../core/library/i18n";
import Config from "../../../config";
import Moment from 'moment';
import 'moment/locale/ru.js';

const TypeColours = {
    'Cover': '#108ee9',
    'Image': '#108ee9',
    'Video': '#b20932'
};


export class MediaItem extends React.Component {

    static defaultProps = {
        files: {},
        title: '',
        CAN_HIDE: false,
        type: ''
    };

    static propTypes = {
        files: PropTypes.instanceOf(Object).isRequired,
        title: PropTypes.string.isRequired,
        CAN_HIDE: PropTypes.bool.isRequired,
        type: PropTypes.string.isRequired,
    };

    getMedia = () => {
        let dimensions = ['image-m', 'image-l', 'cover', 'preview', 'medium', 'original'];
        let path = '';
        let files = this.props.files;

        for (let index in dimensions) {
            let i = dimensions[index];
            if (files[i] && files[i].path) {
                path = files[i].path;
                break;
            }
        }

        let path_prefix = ``;
        let original_prefix = ``;
        switch (this.props.type) {
            case "Image":
            case "Cover":
                path_prefix = original_prefix = Config.MEDIA.CDN.IMAGES + "/";
                break;
            case "Video":
                if (this.props.poster && this.props.poster !== "") {
                    path_prefix = Config.MEDIA.CDN.IMAGES + "/";
                    original_prefix = Config.MEDIA.CDN.VIDEO + "/";
                    path = this.props.poster;
                } else {
                    path_prefix = original_prefix = Config.MEDIA.CDN.VIDEO + "/";
                }
                
                break;
            case "PDF":
                path_prefix = Config.MEDIA.CDN.PDF + "/";
                original_prefix = Config.MEDIA.CDN.PDF + "/";
                break;
            default:
                break;
        }

        return {
            "preview": path ? `${path_prefix}${path}` : '',
            "original": files['original'] && files['original'].path ? `${original_prefix}${files['original'].path}` : '',
        };
    };

    getImageTitle = () => {
        let print_data = [];
        if (this.props['title']) {
            print_data.push(this.props['title']);
        }
        if (this.props['description']) {
            print_data.push(this.props['description']);
        }
        if (this.props['properties'] && this.props['properties']['copyright']) {
            print_data.push(this.props['properties']['copyright']);
        }
        return print_data.join(' / ');
    }

    /*@TODO: move to config*/
    generateSiteImage = () => {
        let files = this.props.files;
        let path = files['original']  ? files['original'].path : '';
        if (!path) return '';
        let params = path.split('/');
        params[params.length - 1] = `1280_${params[params.length - 1]}`;
        return `https://cdni.dvapiva.ru/${params.join('/')}`;
    }

    generateSiteVideo = () => {
        let files = this.props.files;
        if (files['preview'] && files['preview'].path) {
            return `https://video.vm.ru/mp4/${files['preview'].path}`;
        }
        return false;
    }

    isBuilding = (media) => {
        if (media && media.type === 'Video' && ((media.transcoding === null || media.transcoding.progress < 100) && (media.transcoding && media.transcoding.status && media.transcoding.status !== "done"))) {
            return `pending`;
        }
        return ``;
    }

    getImageInfo = () => {
        if (['Image', 'Cover'].indexOf(this.props.type) + 1 > 0) {
            if (this.props.files['original'] && this.props.files['original'].width && this.props.files['original'].height) {
                return ` (${this.props.files['original'].width}:${this.props.files['original'].height} px)`
            }
        }
        return ``;
    }

    getImageTitle = () => {
        let params = [];
        if (this.props['title'] !== '') {
            params.push(this.props['title']);
        }
        if (this.props.properties && this.props.properties.copyright) {
            if (this.props.type === 'Video') {
                params.push('Видео: ' + this.props.properties.copyright)
            } else {
                if (['image', 'cover'].indexOf(this.props.type.toLowerCase()) + 1 > 0) {
                    params.push('Фото: ' + this.props.properties.copyright)
                }
            }
        }
        return params.join(' / ');
    }

    render() {
        const view = this.props.view;
        let media = this.getMedia();
        let blockBackground = media.preview ? {backgroundImage: `url(${media.preview})`} : ``;
        let previewImageProps = {
            className: this.props.deleteList.length === 0 ? `preview fimage type_${this.props.type.toLowerCase()} ${this.props.CAN_HIDE ? `can_bookmark` : ``}` : `preview type_${this.props.type.toLowerCase()} ${this.props.CAN_HIDE ? `can_bookmark` : ``}`, 
            style: blockBackground,
            href: media.original,
        };

        if (this.props.deleteList.length === 0) {
            previewImageProps['data-fancybox'] = true;
        } else {
            previewImageProps.onClick = (e) => {
                e.preventDefault();
                if (this.props.deleteList.indexOf(this.props.id) + 1 > 0) {
                    this.props.removeFromDelete(this.props.id);
                } else {
                    this.props.addToDelete(this.props.id);
                }
            };
        }


        return (
            <div className={`media__wrapper`}>
                <div className={`media__item item__${view} ${this.isBuilding(this.props)}`}>
                    <div className="image">
                        {(this.props.CAN_HIDE && this.props.deleteList.length === 0) && (<div className={`bookmark_action`}><Tooltip title={`Избранное`}><Checkbox checked={this.props.is_bookmark} onChange={(e) => {
                            this.props.toggleMediaBookmark(this.props.id, e.target.checked);
                        }}/></Tooltip></div>)}
                        <Tag size={`small`} color={TypeColours[this.props.type] || ""} className={`image__tag`}>
                                {t(`MEDIAGALLERY_TYPE_${this.props.type}`)}{this.props.media_id ? ` / ${this.props.media_id}` : ``}{this.getImageInfo()}
                                {this.props.CAN_HIDE && (<Checkbox
                                    className={`remove_checkbox`}
                                    checked={this.props.deleteList.indexOf(this.props.id) + 1 > 0}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            this.props.addToDelete(this.props.id);
                                        } else {
                                            this.props.removeFromDelete(this.props.id);
                                        }
                                    }}
                                />)}
                        </Tag>
                        <a {...previewImageProps}>
                            <div className={`created_at`}>
                                <span>{Moment(this.props.createdAt).format(Config.DATE_FORMAT)}</span>
                            </div>
                            {this.props.isUsing && <div className={`image--usage`}><Icon type="clock-circle"/></div>}
                            {this.props.expired && <div className={`image--expired`}><Icon type="info" /></div>}
                        </a>
                        {this.props.deleteList.length === 0 && ( <div className="item__actions">
                            {['Video', 'Image', 'Cover'].indexOf(this.props.type) + 1 > 0 && (
                                <React.Fragment>
                                    <Button type={`primary`} icon={"unordered-list"} onClick={() => {
                                        this.props.showMaterialModal(this.props.id)
                                    }}/>
                                    <Button type={`primary`} icon={`edit`} onClick={() => {
                                        this.props.onUpdate(this.props.id, this.props);
                                    }}/>
                                </React.Fragment>
                            )}
                            <Tooltip title={`Скачать оригинал`}>
                                <Button
                                    type={`primary`}
                                    icon={`cloud-download`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(media.original);
                                    }}
                                />
                            </Tooltip>
                            {(['Video'].indexOf(this.props.type) + 1 > 0 && this.generateSiteVideo(media.original)) && (
                                <Tooltip title={`Скачать для сайта`}>
                                    <Button
                                        type={`primary`}
                                        icon={`cloud-download`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(this.generateSiteVideo(media.original));
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {['Image', 'Cover'].indexOf(this.props.type) + 1 > 0 && (
                                <Tooltip title={`Скачать для сайта`}>
                                    <Button
                                        type={`primary`}
                                        icon={`cloud-download`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(this.generateSiteImage(media.original));
                                        }}
                                    />
                                </Tooltip>
                            )}

                                <Popconfirm
                                    title={<><p>Вы уверены, что хотите удалить изображение?</p>{this.props.isCanDropImages && (<><Button type='danger' size='small' onClick={() => this.props.onDeleteForever(this.props.id)}>Удалить навсегда</Button></>)}</>}
                                    okText={t("MEDIAGALLERY_DELETE_OK")}
                                    cancelText={t("MEDIAGALLERY_DELETE_REJECT")}
                                    onConfirm={() => {
                                        this.props.onDelete(this.props.id);
                                    }}
                                >
                                    <Button type={`danger`} icon={`delete`}/>
                                </Popconfirm>

                        </div>)}
                    </div>
                    <div className="item__info">
                        {this.props.view === 'card' && (
                            <div className="title">
                                <Tooltip title={this.getImageTitle()}>{this.getImageTitle()}</Tooltip>
                            </div>
                        )}
                        {this.props.view === 'list' && (
                            <React.Fragment>
                                {this.props.title !== "" && <div className="title">{this.getImageTitle()}</div>}
                                {this.props.description !== "" &&
                                <div className="description">{this.props.description}</div>}
                                <div className="image_info">
                                    {this.props.properties && this.props.properties.copyright ? this.props.properties.copyright + '/ ' : ''}
                                    {Moment(this.props.createdAt).format(Config.DATE_FORMAT)}
                                </div>
                                {this.props.keywords && <div className="keywords">{
                                    this.props.keywords.split(",").map((i, index) => {
                                        i = i.trim();
                                        if (i !== "") {
                                            return <Tag key={`keyword_${this.props.id}_${index}`}>{i}</Tag>
                                        }
                                        return null;
                                    })
                                }</div>}
                                {this.props.user_email &&
                                <div className={`user_email`}>Загрузил: {this.props.user_email}</div>}
                            </React.Fragment>
                        )}

                    </div>
                </div>
            </div>
        );
    }
}
