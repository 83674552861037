import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Empty, Layout, Spin, notification, message, Button, Alert, Modal} from 'antd';
import Moment from 'moment';

import {AppConsumer} from './../../context/app';
import * as Actions from "./../../store/documents/actions";
import * as C from "./../../store/documents/constants";
import GetProp from './../../core/library/objects/getprop';
import {t} from './../../core/library/i18n';
import * as Views from './add/index';
import Config from './../../config';
import LayoutController from './../../core/components/controller_layout';
import ucfirst from './../../core/library/string/ucfirst';


const mapStateToProps = state => ({
    answer: state.DocumentsReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});


const UpdateIntervalLock = 30 * 1000;

class AddDocumentController extends React.Component {

    static defaultProps = {
        match: {},
    };

    static propTypes = {
        match: PropTypes.instanceOf(Object).isRequired,
        actions: PropTypes.instanceOf(Object).isRequired,
        answer: PropTypes.instanceOf(Object).isRequired,
    };

    constructor(props) {
        super(props);
        const docType = GetProp(this.props, 'match.params.type');
        const Id = GetProp(this.props, 'match.params.id');
        this.state = {
            id: Id || false,
            type: docType || "unknown",
            loading: true,
            item: {},
            isEdit: false,
            userLock: {},
            showLock: false,
            stashLoaded: false,
            stash: {},
            isError: false,
            showModal: false,
            prepare: false,
            isRegionLock: false,
        };
    }

    deleteMedia = (mediaId) => {
        if (this.state.item && this.state.item.id) {
            let media = this.state.item.media || [];
            media = media.filter(i => i.id !== mediaId);
            let item = {...this.state.item};
            item.media = media;
            this.setState({ item });
        }
    };


    setMediaSort = (media) => {
        if (this.state.item && this.state.item.id) {
            let item = {...this.state.item};
            item.media = media;
            this.setState({ item });
        }
    }

    updateMediaData = (mediaItem) => {
        console.log('updateMediaData', mediaItem)
        if (this.state.item && this.state.item.id) {
            let media = this.state.item.media || [];
            let mediaIds = media.map(i => i.id);
            if (mediaItem.main) {
                media = media.map(i => {
                    if (i.type === "Video") {
                        if (i.id !== mediaItem.id) {
                            return {...i, ...{main: false}};
                        }
                    }
                    return i
                })
            }

            if(mediaIds.indexOf(mediaItem.id) + 1 === 0) {
                media.push(mediaItem);
                let item = {...this.state.item};
                item.media = media;
                this.setState({ item })
            } else {
                media = media.map(i => {
                    if (i.id === mediaItem.id) {
                        i.title = mediaItem.title;
                        if (mediaItem.embed) {
                            i.embed = mediaItem.embed;
                        }
                        i.main = mediaItem.main;
                        i.properties = mediaItem.properties;
                    }
                    return i;
                });
                let item = {...this.state.item};



                item.media = media;
                this.setState({ item });
            }

        }
    };

    updateMedia = (mediaItem) => {
        console.log('updateMedia', mediaItem)
        if (this.state.item && this.state.item.id) {
            let media = this.state.item.media || [];
            let mediaIds = media.map(i => i.id);
            if (mediaIds.indexOf(mediaItem.id) + 1 === 0) {
                media.push(mediaItem);
                let item = {...this.state.item};
                item.media = media;
                this.setState({ item }, () => {
                    this.onUpdate({}, true);
                });
            }
        }
    };

    onCreate = (values) => {
        values["type"] = ucfirst(this.state.type);
        this.props.actions.CreateDocument(values);
    };


    onUpdate = (values, silent = false) => {

        values.media = this.state.item.media || [];

        

        if (!values.poll) {
            values.poll = null;
        }

        if (values.cover) {
            values.media = values.media.filter(i => i.type !== "Cover");
            let mediaIds = values.media.map(i => i.id);
            if (mediaIds.indexOf(values.cover) + 1 === 0) {
                values.media.push(values.cover);
            }
            delete values.cover;
            if (this.state.type === 'news') {
                values.options = values.options || {};
                values.options.needPhoto = true;
            }
        }

        if (values.media_cover) {
            values.media = values.media.filter(i => i.type !== "Cover");
            let mediaIds = values.media.map(i => i.id);
            if (mediaIds.indexOf(values.media_cover) + 1 === 0) {
                values.media.push(values.media_cover);
            }
            delete values.media_cover;
        }

        if (values.media_video) {
            values.media = values.media.filter(i => i.type !== "Video");
            let mediaIds = values.media.map(i => i.id);
            if (mediaIds.indexOf(values.media_video) + 1 === 0) {
                values.media.push(values.media_video);
            }
            delete values.media_video;
        }

        if (values.audio) {
            values.media = values.media.filter(i => i.type !== "Video");
            let mediaIds = values.media.map(i => i.id);
            if (mediaIds.indexOf(values.audio) + 1 === 0) {
                values.media.push(values.audio);
            }
            delete values.audio;
        }


        if (values.text !== undefined) {
            values.text = values.text || "";
        }
        if(values.crosslinks && !Array.isArray(values.crosslinks)) {
            values.crosslinks = [values.crosslinks];
        }

        if (values.morematerials && !Array.isArray(values.morematerials)) {
            values.morematerials = [values.morematerials];
        } else {
            if (!values.morematerials) {
                values.morematerials = [];
            }
        }


        if (values.title !== undefined) {
            if (values.mcats === undefined) {
                values.mcats = null;
            }
        }
        values.redirectTo = values.redirectTo || "";
        // map to array of bson object ids
        values.media = values.media.map(i => i.id ? i.id : i);

        if (values.media && values.media.length > 0) {
            values.options = values.options || {};
            values.options.media_sort = values.media.map(i => i.id ? i.id : i);
        }

        
        if (values.media_cover_square) {
            values.media = values.media.filter(i => i.type !== "CoverSquare");
            let mediaIds = values.media.map(i => i.id);
            if (mediaIds.indexOf(values.media_cover_square) + 1 === 0) {
                values.media.push(values.media_cover_square);
            }
            delete values.media_cover_square;
        }

        if (silent === true) {
            this.props.actions.UpdateDocumentSilent(this.state.id, values);
        } else {
            this.deleteStash();
            this.props.actions.UpdateDocument(this.state.id, values);
        }
    };

    getLayout = () => {
        if (Config.VIEWS && Config.VIEWS.documents_add && Config.VIEWS.documents_add[this.state.type] && Views[Config.VIEWS.documents_add[this.state.type]]) {
            return Views[Config.VIEWS.documents_add[this.state.type]];
        }
        if(Config.PROJECT_PREFIX && Views[`add_default_${Config.PROJECT_PREFIX}`]) {
            return Views[`add_default_${Config.PROJECT_PREFIX}`];
        }
        return Views["add_default"];
    };

    loadDocument = () => {
        this.props.actions.GetDocument(this.state.id);
    };

    onPreview = () => {
        this.props.history.push(`/documents/${this.state.type}/${this.state.id}/preview`);
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.state.id !== false && this.state.id !== "create") {
            this.loadDocument();
        }
    }

    removeLock = () => {
        this.setState({loading: true}, () => {
            this.props.actions.RemoveLock(this.state.id);
        });
    };

    updateStash = (data = {}) => {
        this.props.actions.UpdateStash(this.state.id, data);
    };

    windowCloseListener = () => {
        this.props.actions.RemoveUserLock(this.state.id);
    };

    prepareCompare = (item) => {
        return {"title": item.title, "text": item.text, 'summary': item.summary, 'subtitle': item.subtitle};
    };

    recoveryFromStash = () => {
        let stashItem = this.prepareCompare(this.state.stash);
        let item = {...this.state.item};
        Object.keys(stashItem).map(i => {
            if (stashItem[i] !== undefined) {
                item[i] = stashItem[i];
            }
            return i;
        });

        this.setState({
            showModal: false,
            item
        });
    };

    deleteStash = () => {
        this.setState({showModal: false}, () => {
            this.props.actions.DeleteStash(this.state.id);
        });
    };


    sendPush = (message) => {
        if (message.topics && message.topics.length === 1 && message.topics[0] === "") {
            delete message.topics;
        }
        if (!message.topics) {
            delete message.topics;
        }
        this.props.actions.AddPush(message);
    };

    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.REGIONS_START_UPLOAD:
                    this.setState({ loading: true, isRegionLock: true });
                    break;
                case C.REGIONS_FINISH_UPLOAD:
                    message.success(`Документ успешно опубликован`);
                    setTimeout(() => {
                        this.loadDocument();
                    }, 500);
                    break;
                case C.REGIONS_ERROR_UPLOAD:
                    message.error(`Ошибка публикации документа`);
                    console.log(p.answer);
                    this.setState({ loading: false });
                    break;
                case C.PUSHES_ADD_FINISH:
                    message.success(t('PUSH_SEND'));
                    break;
                case C.PUSHES_ADD_ERROR:
                    message.success(t('PUSH_SEND_ERROR'));
                    break;
                case C.DOCUMENT_STASH_LOAD_FINISH:
                    if (this.state.stashLoaded === false) {
                        const dataStash = p.answer.data.data || {};
                        if (dataStash && dataStash.id && JSON.stringify(this.prepareCompare(this.state.item)) !== JSON.stringify(this.prepareCompare(dataStash))) {
                            this.setState({
                                showStash: true,
                                showModal: true,
                                stash: dataStash,
                                stashLoaded: true,
                            })
                        }
                    }
                    break;
                case C.DOCUMENT_STASH_LOAD_ERROR:
                    this.setState({stashLoaded: true});
                    break;

                case C.DOCUMENT_LOCK_REMOVE_FINISH:
                    this.setState({loading: false, showLock: false}, () => {
                        message.success(t(`LOCK_REMOVE`));
                    });
                    break;
                case C.DOCUMENT_LOCK_REMOVE_ERROR:
                    this.setState({loading: false}, () => {
                        message.error(t(`LOCK_REMOVE_ERROR`));
                    });
                    break;

                case C.DOCUMENT_UPDATE_START:
                    this.setState({loading: true});
                    break;

                case C.DOCUMENT_UPDATE_ERROR:
                    notification.error({
                        message: t('ERROR_UPDATE_DOCUMENT'),
                    });
                    this.setState({loading: false});
                    break;
                case C.DOCUMENT_UPDATE_FINISH:
                    message.success(t('DOCUMENT_UPDATED'));
                    setTimeout(() => {
                        this.loadDocument();
                    }, 500);
                    break;

                case C.DOCUMENT_LOAD_START:
                    this.setState({loading: true});
                    break;

                case C.DOCUMENT_LOCK_SET_START:
                    break;

                case C.DOCUMENT_LOCK_SET_FINISH:
                    message.success(t('SUCCESS_SET_LOCK'));
                    window.addEventListener('close', this.windowCloseListener, false);
                    clearTimeout(this.intervalLock);

                    this.intervalLock = setTimeout(() => {
                        this.props.actions.SetLock(this.state.id);
                    }, UpdateIntervalLock);

                    if (this.state.stashLoaded === false) {
                        this.props.actions.GetStash(this.state.id);
                    }
                    break;

                case C.DOCUMENT_LOCK_SET_ERROR:
                    message.error(t('ERROR_SET_LOCK'));
                    break;

                case C.DOCUMENT_LOAD_FINISH:
                    let data = p.answer.data && p.answer.data.data ? p.answer.data.data : {};
                    if (data.category && data.category.id === "") {
                        delete data.category;
                    }

                    if (this.state.isRegionLock === true && data.published === true) {
                        this.setState({ loading: false,  item: data }, () => {
                            setTimeout(() => {
                                this.loadDocument();
                            }, 5000);
                        })

                    } else {
                        this.setState({
                            loading: false,
                            item: data,
                            isEdit: true,
                            isRegionLock: false,
                        }, () => {
                            if (this.state.item.id) {
                                if (Config.WORKFLOW && Config.WORKFLOW.LOCK_FOR) {
                                    if (Config.WORKFLOW.LOCK_FOR.indexOf(this.state.item.type.toLowerCase()) + 1 > 0) {
                                        if (this.state.item.locks && this.state.item.locks.user_id && this.state.item.locks.user_id.id) {
                                            if (this.state.item.locks.user_id.id !== window.user.id) {
                                                this.setState({
                                                    showLock: true,
                                                }, () => {
                                                    this.intervalReload = setTimeout(() => {
                                                        this.loadDocument();
                                                    }, UpdateIntervalLock)
                                                });
                                            } else {
                                                this.setState({showLock: false}, () => {
                                                    this.props.actions.SetLock(this.state.id)
                                                });
                                            }
                                        } else {
                                            this.setState({showLock: false}, () => {
                                                this.props.actions.SetLock(this.state.id)
                                            })
                                        }
                                    }
                                }
                            } else {
                                this.setState({isError: true});
                            }
                        });
                    }

                    break;
                case C.DOCUMENT_LOAD_ERROR:
                    notification.error({
                        message: t('DOCUMENT_LOAD_ERROR'),
                        description: t('REPEAT_AGAIN_LATER'),
                    });
                    break;

                case C.DOCUMENT_CREATE_START:
                    this.setState({loading: true});
                    break;
                case C.DOCUMENT_CREATE_ERROR:
                    this.setState({loading: false}, () => {
                        console.error(`Error create document: `, p.answer);
                        notification.error({
                            message: t('DOCUMENT_CREATE_ERROR'),
                            description: t('REPEAT_AGAIN_LATER'),
                        });
                    });
                    break;
                case C.DOCUMENT_CREATE_FINISH:
                    notification.success({
                        message: t('DOCUMENT_CREATE_FINISH'),
                        delay: 3,
                    });
                    setTimeout(() => {
                        window.location.href = `/documents/${this.state.type}/${p.answer.data.id}`;
                    }, 3000);
                    break;
                case C.DOCUMENT_TYPE_START:
                    this.setState({loading: true});
                    break;
                case C.DOCUMENT_TYPE_ERROR:
                    notification.error({ message: t('ERROR_MOVE_TYPE')});
                    setTimeout(() => {
                        this.setState({ loading: false });
                    }, 3000);
                    break;
                case C.DOCUMENT_TYPE_FINISH:
                    notification.success({ message: t('TYPE_UPDATED'), description: t('REDIRECT_MESSAGE')});
                    let typeData = p.answer.data && p.answer.data.data ? p.answer.data.data : null;
                    if (typeData) {
                        setTimeout(() => {
                            window.location.href = `/documents/${typeData.type.toLowerCase()}/${typeData.id}`;
                        }, 3000);
                    }
                    break;
                default:
                    break;
            }
        }
    };

    componentWillUnmount() {
        clearTimeout(this.intervalLock);
        clearTimeout(this.intervalReload);
        window.removeEventListener('close', this.windowCloseListener, false);
        this.props.actions.RemoveUserLock(this.state.id);
    }

    updateItem = (item) => {
        this.setState({ item }, () => {});
    };

    updateUrl = (url) => {
        this.props.history.push(url);
    };

    updateMaterialType = (type = '') => {
        if (type !== '' && this.state.item.type !== type) {
            this.props.actions.UpdateDocumentType(this.state.item.id, { type });
        }
    };

    setFields = (field, values) => {
        let item = this.state.item;
        item[field] = values;
        this.setState({ item });
    };

    onPublishToRegions = (id) => {
        if (id) {
            this.props.actions.UploadRegions(id)
        }
    }


    render() {
        if (this.state.type === "unknown") return <div className={`container container__empty`}><Empty/></div>;
        return (
            <Layout.Content>
                {this.state.isError === true &&
                <div style={{padding: 12}}><Alert type={`error`} message={t('DOCUMENT_NOT_FOUND')}/></div>}
                <Spin spinning={this.state.loading}
                      wrapperClassName={`${this.state.loading ? "app_loading_nflex" : ""}`}
                      tip={t('LOADING_DOCUMENT')}>
                    <React.Fragment>
                        <Modal
                            destroyOnClose={true}
                            visible={false}
                            title={t('STASH_CHANGES')}
                            onCancel={() => {
                                this.setState({showModal: false})
                            }}
                            footer={[
                                <Button key={`recover_btn`} onClick={this.recoveryFromStash} type={`primary`}
                                        icon={`cloud`}>{t('RECOVER')}</Button>,
                                <Button key={`delete_btn`} onClick={this.deleteStash} type={`danger`}
                                        icon={`delete`}>{t('DELETE_STASH')}</Button>,
                            ]}
                        >
                            <div className={`stash`}>
                                <div className={`stash--row`}>
                                    {t('DOCUMENT_LAST_EDIT_USER')}: {Moment(this.state.item.updatedAt).format('YYYY-MM-DD HH:mm:ss')} {t('user_by')} {this.state.item.updatedBy ? this.state.item.updatedBy.email : ""}
                                </div>
                                <div className={`stash--row`}>
                                    {t('DOCUMENT_LAST_EDIT_STASH')}: {Moment(this.state.stash.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                                </div>
                            </div>
                        </Modal>
                        {this.state.showLock && (<div className={`document--lock`}>
                            <div>{t('DOCUMENT_LOCK')}: {this.state.item.locks && this.state.item.locks.user_id ? this.state.item.locks.user_id.email : ""}</div>
                            <div><Button type={`primary`} icon={`reload`}
                                         onClick={() => this.removeLock()}>{t('REMOVE_LOCK')}</Button></div>
                        </div>)}
                        {(this.state.isError === false && this.state.prepare === false) && (
                            <AppConsumer>
                                {context => {
                                    const View = this.getLayout();
                                    return (
                                        <LayoutController
                                            showLock={this.state.showLock}
                                            isEdit={this.state.isEdit}
                                            item={this.state.item}
                                            loading={this.state.loading}
                                            onUpdate={this.onUpdate}
                                            onCreate={this.onCreate}
                                            onPreview={this.onPreview}
                                            updateStash={this.updateStash}
                                            updateItem={this.updateItem}
                                            updateMaterialType={this.updateMaterialType}
                                            updateMedia={{
                                                update: this.updateMedia,
                                                delete: this.deleteMedia,
                                                set: this.updateMediaData,
                                                setMediaSort: this.setMediaSort,
                                            }}
                                            layout={View}
                                            options={context}
                                            layout_type={this.state.isEdit ? `EDIT` : 'ADD'}
                                            showBack={true}
                                            updateUrl={this.updateUrl}
                                            sendPush={this.sendPush}
                                            setFields={this.setFields}
                                            onPublishToRegions={this.onPublishToRegions}
                                            CreateDocument={this.props.actions.CreateDocument}
                                            {...this.state}
                                        />
                                    );
                                }}
                            </AppConsumer>
                        )}
                    </React.Fragment>
                </Spin>
            </Layout.Content>
        );
    }
}

export const AddDocument = connect(
    mapStateToProps,
    mapDispatchProps
)(AddDocumentController);

export default AddDocument;

